    // 底部内容
    .footer {
        padding: 50px 0.625rem;
        margin-top: -5px;
        background-color: #111;
        border-top: 1px solid #555;
        display: block;

        &-wrapper {
            max-width: 72rem;
            margin: 0 auto;

            hr {
                margin: 1.25rem 0;
                border: 1px solid #555;
            }

            .sponsor {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                &-title {
                        color: #666;
                        line-height: 1.25rem;
                        font-size: 14px;
                        white-space: nowrap;
                        height: 2.25rem;
                        display: block;
                        margin-bottom: 0.625rem;
                        margin-top: 1rem;
                }
                &-img {
                    padding-bottom: 0.875rem;
                    display: inline-block;
                    max-width: 100%;
                    height: auto;
                    vertical-align: middle;
                }

                .item {
                    text-align: center;
                    cursor: pointer;
                    width: 100%;
                    display: block;
                    a {
                        display: block;
                        text-decoration: none;
                    }
                }
            }

            .partner {
                justify-content: center;
                display: inline-flex;

                &-title {
                    color: #666;
                    line-height: 1.25rem;
                    font-size: 14px;
                    white-space: nowrap;
                    height: 2.25rem;
                    display: block;
                    margin-bottom: 0.625rem;
                    margin-top: 1rem;
                }

                .item {
                    text-align: center;
                    padding: 0 0.75rem;
                    width: 14%;
                    margin: 0.625rem 0;

                    &.game {
                        width: 30%;
                    }
                    &.secure {
                        width: 12%;
                    }
                    .partner-img {
                        padding: 0.125rem 0.25rem;
                        display: inline-block;
                        margin-right: 0.625rem;
                        margin-bottom: 0.625rem;
                        vertical-align: middle;
                    }
                    a.img-footer-link {
                        cursor: pointer;
                    }
                }
            }
        }

        /* 版权、 pay 、安全、责任*/
        .copyright-pay-safe {
            display: flex;
            padding: .5rem;
            background-color: rgba(229, 231, 235, 1);
            box-sizing: border-box;
            justify-content: center;

            h6 {
                white-space: nowrap;
            }

            .copyright,
            .safe,
            .responsibillity {
                width: 16.666667%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                &.copyright .icon {
                    width: 1.6875rem;
                    height: 2.0625rem;
                    margin-left: 0.625rem;
                    background: url(/static/images/hayhar/first-cagayan.png) no-repeat 0 0;
                    background-size: cover;
                    margin: 0 .25rem;

                    &:hover {
                        background: url(/static/images/hayhar/first-cagayan-hover.png) no-repeat 0 0;
                        background-size: cover;
                    }
                }

                &.safe .icon {
                    width: 5.375rem;
                    height: 2.0625rem;
                    background: url(/static/images/hayhar/iovation.png) no-repeat 0 0;
                    background-size: cover;
                    margin-left: 0.625rem;

                    &:hover {
                        background: url(/static/images/hayhar/iovation-hover.png) no-repeat 0 0;
                        background-size: cover;
                    }
                }

                &.responsibillity>div {
                    display: flex;
                    flex-wrap: wrap;
                }

                &.responsibillity .icon {
                    width: 2.0625rem;
                    height: 2.0625rem;
                    margin-left: 0.625rem;

                    &.icon.icon-1 {
                        background: url('/static/images/hayhar/game-card.png') no-repeat 0 0;
                        background-size: cover;

                        &:hover {
                            background: url('/static/images/hayhar/game-card-hover.png') no-repeat 0 0;
                            background-size: cover;
                        }
                    }

                    &.icon.icon-2 {
                        background: url('/static/images/hayhar/up-21.png') no-repeat 0 0;
                        background-size: cover;

                        &:hover {
                            background: url('/static/images/hayhar/up-21-hover.png') no-repeat 0 0;
                            background-size: cover;
                        }
                    }
                }
            }


            // 付款方式
            .bank-pay {
                display: flex;
                flex-grow: 1;
                align-items: center;
                flex-wrap: wrap;

                .icon {
                    width: 4.125rem;
                    height: 2.0625rem;
                    margin: 0 0.3125rem;
                }

                .icon-1 {
                    background: url('/static/images/hayhar/easypay.png') no-repeat 0 0;

                    &:hover {
                        background: url('/static/images/hayhar/easypay-hover.png') no-repeat 0 0;
                        background-size: cover;
                    }
                }

                .icon-2 {
                    background: url('/static/images/hayhar/localbank.png') no-repeat 0 0;
                    width: 3.6875rem;
                    height: 2.0625rem;

                    &:hover {
                        background: url('/static/images/hayhar/localbank-hover.png') no-repeat 0 0;
                    }
                }

                .icon-3 {
                    background: url('/static/images/hayhar/fastbaht.png') no-repeat 0 0;

                    &:hover {
                        background: url('/static/images/hayhar/fastbaht-hover.png') no-repeat 0 0;
                    }

                    width: 5.375rem;
                }

                .icon-4 {
                    width: 2.4375rem;
                    height: 2.0625rem;
                    background: url('/static/images/hayhar/line.png') no-repeat 0 0;

                    &:hover {
                        background: url('/static/images/hayhar/line-hover.png') no-repeat 0 0;
                    }
                }

                .icon-5 {
                    width: 4.1875rem;
                    background: url('/static/images/hayhar/cashcard.png') no-repeat 0 0;

                    &:hover {
                        background: url('/static/images/hayhar/cashcard-hover.png') no-repeat 0 0;
                    }
                }

                .icon-6 {
                    width: 4.5rem;
                    background: url('/static/images/hayhar/qrcode.png') no-repeat 0 0;

                    &:hover {
                        background: url('/static/images/hayhar/qrcode-hover.png') no-repeat 0 0;
                    }
                }
            }
        }
    }

    @media (max-width:1023px){
		.footer {
			display: none;
		}
	}
// 响应式布局
.style10{
	min-height: 100vh;
	// 手机模式
	@media screen and (max-width:1023px){
		.ant-carousel {
			min-height: auto;
	
			.slick-list {
				.img_wrap {
					img {
						min-height: auto;
					}
				}
			}
		}
		.header {
			&-wrapper {
				align-items: center;
			}
			.logo-wrapper {
				max-width: 180px;
				background: url(/static/images/logo-vn.png) no-repeat center center;
				background-size: 60%;
				height: 2rem;
				.qrp-header-logo {
					width: 100%;
				}
			}
			&-form {
				display: none;
				&-wrapper {
					&>*+* {
						margin-top: 0;
					}
					.login.mobile {
						display: block;
						text-decoration: none;
					}
				}
			}
			&-action {
				display: none;
			}


		}

		// form
		.central {
			position: static;
			transform: none;
			width: 100%;
			margin-top: -4px;
			padding-bottom: 1rem;

			.title {
				border-radius: 0;
			}
			.desc {
				display: flex;
				flex-direction: column;
				align-items: center;
				span {
					width:auto;
					max-width: 70%;
				}
			}
			.form-wrapper {
				border-radius: 0;
			}
		}

		//qrcode
		.QR-Block {
			padding-top: 2.5rem !important;
			padding-bottom: 5rem !important;
			.qrcode {
				// display: none;
			}
	
			.downloadBlock {
				// display: block;
			}
			.btn-area {
				// display: block;
			}
		}

		.footer {
			display: none;
		}
	}
	@media screen and (max-width:767px) {
	}
	@media screen and (max-width:500px) {
		.central {
			.desc {
				&>*+* {
					margin-left: 0;
					margin-top:0.5rem;
				}
			}
		}
	}
}